import * as React from "react";
import { Helmet } from "react-helmet";
import LayoutService from "../../templates/LayoutService";
import {
	blogHeader,
	blogPostContainer,
	postContentBlock,
} from "../../styles/components/blog.module.scss";
import { pageContentMedia } from "../../styles/components/blocks.module.scss";
import { StaticImage } from "gatsby-plugin-image";
import { imgBox } from "../../styles/components/boxes.module.scss";
import "../../styles/components/content.module.scss";
import ServicesList from "../../components/blocks/ServicesList";
import { Link } from "gatsby";

const BlogPage = () => {
	return (
		<LayoutService>
			<Helmet>
				<title>
					Unveiling Red Tide's Impact on Boating in Tampa Bay | Tampa Bay
					Boating Adventures
				</title>
				<meta
					name="description"
					content="Discover the significance of the Red Tide implications in Tampa Bay and its ramicifications for boating activities. Explore the causes of Red Tide, its effects on marine life, and how it disrupts boating experiences. Learn about safety measures, updates, and alternative boating options amidst Red Tide concerns."
				/>
				<meta name="keywords" content="Red Tide" />
			</Helmet>
			<main className="container">
				<div className="row">
					<div className="col-xxs-12">
						<div className={blogHeader}>
							<div className={imgBox}>
								<StaticImage
									alt="Tampa bay red tide downtown"
									src="../../images/blog/tampa-bay-red-tide-downtown.jpg"
								/>
							</div>
						</div>
						<div className={blogPostContainer}>
							<div className="row center">
								<div className="col">
									<h1>Unveiling Red Tide's Impact on Boating in Tampa Bay</h1>
									<p>March 04, 2023</p>
								</div>
							</div>
							<div className="row">
								<div className="col-xxs-12">
									<div className={postContentBlock}>
										<p>
											You may have seen in the news in the past few months that
											the ocean around Florida has been hit with a natural
											phenomenon known as Red Tide. Instances of Red Tide are on
											the increase, partly due to the warmer temperatures and
											different weather patterns in the region. Additionally,
											pollution of the ocean can exacerbate the issue. This can
											have a significant impact on the environment, but also on
											the boating industry. But what is Red Tide? Let’s take a
											closer look:
										</p>
										<div className={pageContentMedia}>
											<div className={imgBox}>
												<StaticImage
													alt="Sunset at beach tampa"
													src="../../images/banners/sunset-at-beach-tampa.jpg"
												/>
											</div>
										</div>
										<h2>1. What Causes Red Tide in Tampa?</h2>
										<p>
											Red tide occurs naturally in the oceans when algae within
											the sea multiplies at a faster rate than usual, leading to
											higher levels of toxins in the ocean which can cause
											significant harm to both animals and humans. A type of
											organism that causes this is known as Karenia Brevis,
											which is known to multiply at a faster rate in warm water,
											good weather conditions, and in nutrient-rich areas. As
											the algae grows and spreads, it begins to discolor the
											water, turning it a dark red shade. The algae are known to
											produce a number of toxins including brevetoxin, which is
											extremely dangerous for sea life. Let’s talk soon can kill
											or significantly injure a number of different marine
											animals and fish, causing issues such as paralysis or
											difficulty breathing.
										</p>
										<hr />
										<h2>2. What Is The Impact of Red Tide for Boating?</h2>
										<p>
											Tampa Bay has seen increased levels of red tide which can
											have a significant impact on activities taking place on or
											in the water. One of the main activities that the red tide
											can impact is boating. This is because the algae on the
											surface of the water can be pulled into the engine intake,
											blocking up the boat’s mechanical system and making it
											more difficult to move or leading to a breakdown of the
											system entirely. On top of this, The algae floating on the
											surface of the water can be very slippery making it more
											difficult to stay in control of the boat. The toxins from
											the algae are also harmful to humans who may be out on
											boats and can lead to breathing issues or significant
											illness if they are exposed for some time. Therefore, it
											is not uncommon for parts of the coastline that have been
											affected by the red tide to be shut down, or for those on
											boats to be told to stay on board and out of the water.
											While this can be frustrating if you have plans, it is
											ultimately for your safety and the safety of everyone on
											board.
										</p>
										<hr />
										<h2>3. Check The Status of Red Tide Near You</h2>
										<p>
											To avoid red tide, and still get out onto the water, it is
											important to ensure that you keep up to date with the {" "}
											<Link
												to="https://myfwc.com/research/redtide/statewide/"
												target="_blank"
											>
												Florida Fish And Wildlife Conservation Commission
											</Link>{" "}
											website, which gives live updates on the red tide
											situation. Those that are visiting the beaches in St.
											Petersburg, Clearwater, and other Gulf Coast beaches will
											notice the impact. If you are planning to charter a boat
											with us, however, do not worry about the impact of Red
											Tide, as we stay in the area closest to Tampa Bay, further
											North, and avoid most of the issues.
										</p>
										<hr />
										<h2>Select Your Party Boat Charter</h2>
										<h3>
											See what all's included with our wide variety of party
											boat charter packages.
										</h3>
									</div>
								</div>
							</div>
						</div>
						<ServicesList />
					</div>
				</div>
			</main>
		</LayoutService>
	);
};

export default BlogPage;
